
























































import { Component, Inject, Prop, Vue } from "vue-property-decorator";

import { Filter, FilterField } from "@/models/filters";

import Papaparse from "papaparse";
import delay from "@/helpers/delay";
import { distinct } from "@/helpers/distinct";
import { CaseView } from "@/models/case-maintenance";
import config, { CaseListSource } from "@/config";
import { Dictionary } from "@/models/email-templates";
import FindToken from "@/components/helper/TokenPathFinder/TokenPathFinder"
import { downloadCsv } from "@/components/helper/CsvHelper"
import { getSurroundingTokens } from "@/components/case-list/export-hit-summary-functions"
import ScreeningViewService from "@/services/screening-view-service";
import CaseService from "@/services/case-service";

enum RequestState {
  Default,
  Loading,
  Error
}

@Component
export default class ExportHitSummary extends Vue {
  @Inject() ScreeningViewService!: ScreeningViewService;
  @Inject() CaseService!: CaseService;
  @Prop({ required: true }) filters!: Filter[];
  @Prop({ required: true }) assignment!: string;
  @Prop({ default: 0 }) totalCases!: number;
  @Prop({ default: false}) disabled!: boolean;
  @Prop({ required: true }) filterFields!: FilterField[];

  requestState = RequestState.Default;

  RequestState = RequestState;

  errorMessage = "Unable to Export"
  
  dialogOpen = false;

  exportAmount = 0

  progress = 0;

  get buttonText(): string {
    switch (this.requestState) {
      case RequestState.Error:
        return this.errorMessage;
      default:
        return "Export Hit Context";
    }
  }

  async exportToCsv() {
    // any more then 1500 and its likey to crash the browser
    const maxExport = 1500 
    if(this.exportAmount > maxExport)
    {
      this.errorMessage = `EXCESS > ${maxExport} CASES`
      await this.errorState()
      return
    }
    if (this.requestState !== RequestState.Default) return;

    this.requestState = RequestState.Loading;

    try {
      const csvFile = await this.loadCasesCsv(this.exportAmount);

      downloadCsv(csvFile, "export-hit-context.csv");
      this.requestState = RequestState.Default;
    } catch (e) {
      await this.errorState(5000)
    }
  }

  async errorState(delaySeconds = 2000){
    this.requestState = RequestState.Error;
      await delay(delaySeconds);
      this.requestState = RequestState.Default;
  }

  async loadCasesCsv(maxCases: number): Promise<string> {
    const cases = await this.CaseService.listAllCases(
      this.filters,
      this.filterFields,
      this.assignment,
      (count, total) => {
        this.progress = (count * 100) / total;
      },
      undefined, 
      maxCases   
    );
    this.progress = 0;

    const flattenCases = await this.flattenCases(cases);

    const unparse = {
      data: flattenCases,
      fields:  flattenCases.flatMap(Object.keys).filter(distinct)
    };

    return Papaparse.unparse(unparse);
  }

 async flattenCases(_cases: CaseView[]) {
    const flattenCases = await this.mapWithDelay<CaseView>(_cases, this.ExtractCase, 50)

    const flattenedCases: any[] = [];

    flattenCases.map(cases =>
      cases.map((c: any) => {
        flattenedCases.push(c);
      })
    );

    return flattenedCases;
  }

  async mapWithDelay<T>(array: T[], callback: any, batchSize: number): Promise<any[]> {
    const result = [];
    for (let i = 0; i < array.length; i += batchSize) {
      await new Promise(resolve => setTimeout(resolve, 500));
        const batch: Promise<T>[] = [];
        for (let j = i; j < i + batchSize && j < array.length; j++) {
            batch.push(callback(array[j]));
        }
        const batchResults = await Promise.all(batch);
        result.push(...batchResults);
    }
    return result;
  }


  private async ExtractCase(_case: CaseView) {
    const result: any = {};
    result["Case Ref"] = _case["case-id"];
    result["Created At"] = _case["created-at"];
    result["Status"] = _case["status"];
    result["Assigned To"] = _case["assigned-to"];
    result["Hits"] = _case.hits.length;

    for (const detail of config.caseList.filter(x=>x.source == CaseListSource.CaseDetail)) {
      const caseDetails = _case["case-details"]
        .filter(x => x.key === detail.key)
        .map(x => x.value)
        .filter(distinct)
        .sort();

      result[detail.name] = caseDetails.join(",");
    }

    const resultsWithHits: any[] = [];
    const screening = await this.ScreeningViewService.readScreening(_case["screen-results"][0]["screening-id"])
    _case.hits.forEach(hit => {
      const flattenedHit: any = {};

      flattenedHit.library = hit.library.name;
      flattenedHit.rule = hit.rule.name;

      Object.keys(hit.metadata).forEach(key => {
        flattenedHit[key] = hit.metadata[key];
      });

      hit.matches.forEach(match => {
        const screeningText = FindToken(match.field ,screening)
        resultsWithHits.push({ 
          ...result, 
          ...flattenedHit, 
          keyword: match.keyword,
          text: match.text,
          field: match.field,
          "screening-text": getSurroundingTokens(screeningText, match.position, match.length, 3)
        });
      });
    });

    return resultsWithHits;
  }
}
