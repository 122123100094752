

















































import UserService from '@/services/user-service';
import { Component,  Inject, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/material/Card.vue';
import MultiAssignmentTable from '@/components/case-list/multi-assignment-table.vue';
import { User } from '@/models/case-maintenance.d';
import AssignmentControl from '@/components/assignment-control.vue';
import EcSnackBar from '@/components/common/ec-snackbar.vue';
import { SnackbarOptions } from '@/models/form';

@Component({
  components: {
    Card,
    MultiAssignmentTable,
    AssignmentControl,
    EcSnackBar,
  },
})
export default class MultiAssignmentControl extends Vue {
  @Inject() UserService!: UserService;

  @Prop({ default: () => [] }) items!: any[];

  selectedItems: any[] = [];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  dialog = false;

  toAssignTo = "<Unassigned>";

  users: User[] = [];

  async mounted(): Promise<void> {
    const users = await this.UserService.listUsers({size: 100, includeHidden: false});

    this.users = users._embedded.users;
  }

  showMultiAssignmentPopup() {
    this.dialog = true;
    this.selectedItems = [];
    this.$emit('multi-assignment-active', true);
  }

  close() {
    this.dialog = false;
    this.$emit('multi-assignment-active', false);
  }

  async updateAssignment(event: any) {
    this.toAssignTo = event;
  }

  selected(value: any, selected: boolean): void {
    const index = this.selectedItems.findIndex(
      (item) => (item["case-id"]) === value["case-id"],
    );

    if (selected && index < 0) {
      this.selectedItems.push(value);
    }
    else if (!selected && index >= 0) {
      this.selectedItems.splice(index, 1);
    }
  }

  selectAll(values: any[], all: boolean): void {
    values.forEach(value => this.selected(value, all));
  }

  async performMultiAssignment() {
    this.snackbarOptions.value = false;
    this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeProgressOptions("Assigning selected cases to selected user ...")});

    if (!this.toAssignTo) {
      this.toAssignTo = "<Unassigned>";
    }

    try {
      this.selectedItems.forEach(async c =>  {
        if (this.toAssignTo === "<Unassigned>") {
          if (c["assigned-to"] !== undefined) {
            await this.UserService.unassignCaseId(
              c["case-id"],
              c["assigned-to"],
              { willHandle: () => true }
            );
            c["assigned-to"] = "";
          }
        } else {
          if (c["assigned-to"] !== this.toAssignTo) {
            await this.UserService.assignCaseIdToUser(
              c["case-id"],
              c["assigned-to"],
              this.toAssignTo,
              { willHandle: () => true }
            );
            c["assigned-to"] = this.toAssignTo;
          }
        }
      });

      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions("All selected cases assigned to selected user")});

    } catch (thrown) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions("Failed to re-assign all selected cases to selected user")});

    }
  }
}
