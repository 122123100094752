function calculateEndOffSet(
    endOfMatch: { text: string; index: number; arrIndex: number }, 
    offset: number, 
    splitString: { text: string; index: number; arrIndex: number }[], 
    matchedToken: { text: string; index: number; arrIndex: number }
  ) {
  let endOffset = endOfMatch.arrIndex + offset + 1;
  endOffset = endOffset > splitString.length ? splitString.length : endOffset;

  const trailingEmptyStrings = splitString
    .slice(matchedToken.arrIndex, endOffset + 1)
    .filter(x => x.text == "")
    .length;

  endOffset = endOffset + trailingEmptyStrings;
  endOffset = endOffset > splitString.length ? splitString.length : endOffset;
  return endOffset;
}

function calcualteStartOffset(
    matchedToken: { text: string; index: number; arrIndex: number }, 
    offset: number, 
    splitString: { text: string; index: number; arrIndex: number }[]
  ) {
  let startOffset = matchedToken.arrIndex - offset;
  startOffset = startOffset < 0 ? 0 : startOffset;
  const leadingEmptyStrings = splitString
    .slice(startOffset, matchedToken.arrIndex + 1)
    .filter(x => x.text == "")
    .length;
  startOffset = startOffset - leadingEmptyStrings;
  startOffset = startOffset < 0 ? 0 : startOffset;
  return startOffset;
}

export function getSurroundingTokens(screeningText: string | undefined, matchPostition: number, expectedLength: number, offset: number): string {

    if(screeningText === undefined) return "Unable to locate screening text";
    
    let currentLength = 0;
    const splitString = screeningText
      .replace(/\r/g, " ")
      .replace(/\n/g, " ")
      .split(" ")
      .map((x, index) => {
          const mappedValue= { text: x, index: currentLength, arrIndex:  index}
          currentLength += x.length + 1
          return mappedValue
        }
      );

    const matchedToken = splitString
      .filter(x => x.index <= matchPostition)
      .reverse()[0];

    let endOfMatch = matchedToken;
    if(splitString[matchedToken.arrIndex].text.length < expectedLength){
        const endOfMatchToken = splitString
          .filter(x => x.index <= matchedToken.index + expectedLength)
          .reverse()[0]

        endOfMatch = endOfMatchToken
    }

    const startOffset = calcualteStartOffset(matchedToken, offset, splitString);

    const endOffset = calculateEndOffSet(endOfMatch, offset, splitString, matchedToken);

    return splitString
      .slice(startOffset, endOffset)
      .map(x=>x.text)
      .join(" ")
  }



