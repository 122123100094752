



















import { User } from "@/services/auth-service";
import {  Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    
  },
})
export default class MultiAssignment extends Vue {
    @Prop({ default: () => [] }) users!: User[];
    
    assignToUser= null;

    assignCases(){
        this.$emit("assignCases", this.assignToUser)
    }

    allUsers(): any[]{  
        return [{ id: null, name: "<Unassigned>" }, ...this.users]
    }
}
