
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';

@Component
export default class CaseTableExpandComponent extends Vue {
  @Prop() model!: CaseView;

  showCurrentHeader() {
    return this.currentHits?.length == 0 || this.pastHits?.length
  }

  get currentHits() {
    return this.model?.hits?.filter(hit => hit.isCurrent);
  }

  showPastHeader() {
    return this.pastHits?.length
  }

  get pastHits() {
    return this.model?.hits?.filter(hit => !hit.isCurrent);
  }
}
