





























import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import CaseService from "@/services/case-service";

import { Filter, FilterField } from "@/models/filters";

import Papaparse from "papaparse";
import delay from "@/helpers/delay";
import { distinct } from "@/helpers/distinct";
import { CaseView } from "@/models/case-maintenance";
import config, { CaseListSource } from "@/config";
import { downloadCsv } from "../helper/CsvHelper";

enum RequestState {
  Default,
  Loading,
  Error
}

@Component
export default class ExportToCsvButton extends Vue {
  @Inject() CaseService!: CaseService;
  @Prop({ required: true }) filters!: Filter[];
  @Prop({ required: true }) assignment!: string;
  @Prop({ default: 0 }) totalCases!: number;
  @Prop({ required: true }) filterFields!: FilterField[];

  requestState = RequestState.Default;

  RequestState = RequestState;

  progress = 0;

  get buttonText(): string {
    switch (this.requestState) {
      case RequestState.Error:
        return "Unable to Export CSV";
      default:
        return "Export To CSV";
    }
  }

  async exportToCsv() {
    if (this.requestState !== RequestState.Default) return;

    this.requestState = RequestState.Loading;

    try {
      const csvFile = await this.loadCasesCsv();

      downloadCsv(csvFile, "CasesExport.csv");
      this.requestState = RequestState.Default;
    } catch {
      this.requestState = RequestState.Error;
      await delay(5000);
      this.requestState = RequestState.Default;
    }
  }

  async loadCasesCsv(): Promise<string> {
    this.progress = 0;

    const cases = await this.CaseService.listAllCases(
      this.filters,
      this.filterFields,
      this.assignment,
      (count, total) => {
        this.progress = (count * 100) / total;
      }
    );

    const flattenCases = this.flattenCases(cases);

    const unparse = {
      data: flattenCases,
      fields: flattenCases.flatMap(Object.keys).filter(distinct)
    };

    return Papaparse.unparse(unparse);
  }

  flattenCases(_cases: CaseView[]) {
    const flattenCases = _cases.map(this.flattenCaseForCsv);

    const flattenedCases: any[] = [];

    flattenCases.map(cases =>
      cases.map(c => {
        flattenedCases.push(c);
      })
    );

    return flattenedCases;
  }

  flattenCaseForCsv(_case: CaseView) {
    return this.ExtractCase(_case);
  }

  private ExtractCase(_case: CaseView) {
    const result: any = {};
    result["Case Ref"] = _case["case-id"];
    result["Created At"] = _case["created-at"];
    result["Status"] = _case["status"];
    result["Assigned To"] = _case["assigned-to"];
    result["Hits"] = _case.hits.length;

    for (const detail of config.caseList.filter(x=>x.source == CaseListSource.CaseDetail)) {
      const caseDetails = _case["case-details"]
        .filter(x => x.key === detail.key)
        .map(x => x.value)
        .filter(distinct)
        .sort();

      result[detail.name] = caseDetails.join(",");
    }

    const resultsWithHits: any[] = [];

    _case.hits.forEach(hit => {
      const flattenedHit: any = {};

      flattenedHit.library = hit.library.name;
      flattenedHit.rule = hit.rule.name;

      Object.keys(hit.metadata).forEach(key => {
        flattenedHit[key] = hit.metadata[key];
      });

      hit.matches.forEach(match => {
        resultsWithHits.push({ ...result, ...flattenedHit, text: match.text });
      });
    });

    return resultsWithHits;
  }
}
