






import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';
import config from '@/config';
import { distinct } from '@/helpers/distinct';

@Component
export default class CaseDetailStringComponent extends Vue {
  @Prop({ required: true }) case!: CaseView;
  @Prop({ required: true }) detail!: typeof config.caseList[0];

  get detailString() {
    const details = this.case['case-details']
      .filter(x => x.key === this.detail.key)
      .map(x => x.value)
      .filter(distinct)
      .sort();

    return details.join(', ');
  }
}
