








import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';
import config from '@/config';
import CaseDetailString from './case-detail-string.vue';
import CaseDetailLink from './case-detail-link.vue';

@Component
export default class CaseDetailComponent extends Vue {
  @Prop({ required: true }) case!: CaseView;
  @Prop({ required: true }) detail!: typeof config.caseList[0];

  get component() {
    switch (this.detail.type)
    {
      case 'link': return CaseDetailLink;
      default: return CaseDetailString;
    }
  }
}
